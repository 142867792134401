import React from "react";
import NoMatch from "./NoMatch";

export interface RouteInfo {
  path: string;
  component: any;
  routes?: RouteInfo[];
  lazy?: boolean;
  protected?: boolean;
}

const ReactLazyPreload = (importStatement: any) => {
  const Component: any = React.lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

const Dashboard = ReactLazyPreload(() => import("./pages/dashboard/Dashboard"));
Dashboard.preload();

const Login = ReactLazyPreload(() => import("./pages/login/Login"));
Login.preload();

const ChangePassword = ReactLazyPreload(
  () => import("./pages/change-password/ChangePassword")
);
ChangePassword.preload();

const Profile = ReactLazyPreload(() => import("./pages/profile/Profile"));
Profile.preload();

const Outlets = ReactLazyPreload(() => import("./pages/outlets/Outlets"));
Outlets.preload();

const OutletUsers = ReactLazyPreload(() => import("./pages/outlet-users/OutlerUsers"));
OutletUsers.preload();

const Role = ReactLazyPreload(() => import("./pages/roles-external/RolesExternal"));
Role.preload();

const TenantUser = ReactLazyPreload(() => import("./pages/tenant-users/TenantUsers"));
TenantUser.preload();

const Customer = ReactLazyPreload(() => import("./pages/customers/Customers"));
Customer.preload();

const Wallets = ReactLazyPreload(() => import("./pages/wallets/Wallets"));
Wallets.preload();

const TenantReferrals = ReactLazyPreload(() => import("./pages/tenant-referrals/TenantReferrals"));
TenantReferrals.preload();

const CreditActivities = ReactLazyPreload(() => import("./pages/reports/credits-activity/CreditsActivity"));
CreditActivities.preload();

const CustomerCards = ReactLazyPreload(() => import("./pages/stamp-card/customers/Customers"));
CustomerCards.preload();

const CardSettings = ReactLazyPreload(() => import("./pages/stamp-card/settings/CardSettings"));
CardSettings.preload();

export const routes: RouteInfo[] = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    protected: true,
  },
  {
    path: "/profile/account",
    component: Profile,
    protected: true,
  },
  {
    path: "/profile/change-password",
    component: ChangePassword,
    protected: true,
  },
  {
    path: "/outlets",
    component: Outlets,
    protected: true,
  },
  {
    path: "/role-permission",
    component: Role,
    protected: true,
  },
  {
    path: "/users",
    component: TenantUser,
    protected: true,
  },
  {
    path: "/customers",
    component: Customer,
    protected: true,
  },
  {
    path: "/credit",
    component: Wallets,
    protected: true,
  },
  {
    path: "/referral",
    component: TenantReferrals,
    protected: true,
  },
  {
    path: "/reports",
    component: CreditActivities,
    protected: true
  },{
    path: "/stamp-card/customers",
    component: CustomerCards,
    protected: true
  },
  {
    path: "/stamp-card/settings",
    component: CardSettings,
    protected: true
  },
  {
    path: "/",
    component: Dashboard,
    protected: true,
  },
  {
    path: "*",
    component: NoMatch,
  },
];
