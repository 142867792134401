import { gql } from "@apollo/client";

export const GET_EXTERNAL_PROFILE = gql`
  query UserProfile {
    userProfile {
      id
      name
      userName
      roleName
      userType
      authId
      tenantId
      currentOperatingOutlet
      sideNavigation {
        id
        name
        route
        order
        icon
        roleType
        parentId
        description
        requireAttention
      }
    }
  }
`;
