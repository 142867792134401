import { Layout, Dropdown, MenuProps } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useAuth } from "../../../hooks/auth/AuthContext";

interface Props {
  collapsed: boolean;
  toggleSideNav?: () => void;
  logout?: () => void;
}

const GeneralHeader = ({ collapsed, toggleSideNav, logout }: Props) => {
  const { Header } = Layout;
  const { authState } = useAuth();
  const userName =
    authState.userProfile !== null ? authState.userProfile.name : "Welcome";

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Link to="/profile/account">
          <FormattedMessage id="PROFILE" />
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to="/profile/change-password">
          <FormattedMessage id="CHANGE_PASSWORD" />
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <div onClick={logout}>
          <FormattedMessage id="LOGOUT" />
        </div>
      ),
    },
  ];

  return (
    <Header
      className="flex justify-between text-indigo-700 align-center font-primary bg-gray-50"
      style={{ padding: 0 }}
    >
      <div className="flex gap-3 px-2">
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            onClick: toggleSideNav,
          }
        )}
      </div>
      <div className="flex px-4">
        <Dropdown
          menu={{ items }}
          className="float-left px-1"
          trigger={["hover", "click"]}
          placement="bottomRight"
        >
          <a href="/#" className="text-indigo-700">
            <UserOutlined />
            <span className="pl-1">{userName}</span>
          </a>
        </Dropdown>
      </div>
    </Header>
  );
};

export default GeneralHeader;
