import { FormattedMessage } from "react-intl";

const NoMatch = () => {
  return (
    <div className="text-white">
      <FormattedMessage id="NOT_FOUND" />
    </div>
  );
};

export default NoMatch;
