import Cookies from "universal-cookie";

const cookies = new Cookies();

export const setTokenCookie = (key: string, value: string) => {
  const isProd = process.env.NODE_ENV === "production";
  cookies.set(key, value, {
    maxAge:
      key === (process.env.REACT_APP_ACCESS_TOKEN || "sc-at") ? 3600 : 18000, //refresh token keep for a week
    domain: isProd ? window.location.hostname : "localhost",
    secure: isProd ? true : undefined,
    sameSite: isProd ? "lax" : undefined,
    path: "/",
  });
};
